<script lang="ts" setup></script>

<template>
  <div>
    Layout: 404
    <slot />
  </div>
</template>

<style scoped></style>
